.react-calendar__tile {
  background: #7489C2; 
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  color: #ffffff;
 
}
  
.react-calendar__tile--active {
  background: #ff0000;
}

.rt-thead .rt-tr .rt-th {
  padding: 10px;
}

.react-calendar__tile:disabled {
  color: #000000;
}
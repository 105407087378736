.LoginForm {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15%;
    text-align: center;
}

.login-container {
    color: black;
    background-color: white; 
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    border-radius: 15px;
    padding: 50px;
}

body {
    min-height: 5vh;
    display: flex;
  }
  
  body, html, .App, #root, .auth-wrapper {
    width: 100%;
    height: 100%;
  }

  .login-h1, .login-h2, .login-h3, .login-h4, .login-h5, .login-h6 {
    font-weight: 500;
    font-family: 'Fira Sans', sans-serif;
    text-align: center;
    margin-bottom: 20px;
  }

  .login-label {
    font-weight: 400;
    font-family: 'Fira Sans', sans-serif;
  }

  .login-btn {
    background-color: #4e73df; 
  }

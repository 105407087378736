  .chart-area {
      position: relative;
      height: 100px;
      width: 100%;
    }
  
  @media (min-width: 768px) {
    .chart-area {
      height: 20rem;
    }
  }

  .report-card-body {
    height: 500px;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }

  .canvas-container {
    height: 40vh;
  }
@import './css/sb-admin-2.min.css';
@import './vendor/fontawesome-free/css/all.min.css';


.ReactTable {
    position: relative;
}
.ReactTable .rt-table .rt-tr {
    display: flex;
    border-bottom: 1px solid #ebebeb;
}
.ReactTable .rt-table .rt-tr .rt-th, .ReactTable .rt-table .rt-tr .rt-td {
    padding: 0;
}
.ReactTable .rt-table .rt-tr .rt-th img, .ReactTable .rt-table .rt-tr .rt-td img {
    height: 40px;
    width: 40px;
}
.ReactTable .rt-table .rt-tr .rt-th a, .ReactTable .rt-table .rt-tr .rt-td a {
    font-size: 1.1487em;
    color: #000;
}
.ReactTable .rt-table .rt-thead .rt-tr {
    color: #999;
    border-width: 3px;
}
.ReactTable .pagination-bottom {
    margin-top: 20px;
}
.ReactTable .pagination-bottom .Table__pagination {
    display: flex;
}
.ReactTable .pagination-bottom .Table__pageButton {
    background-color: #fff;
    color: #7b7b7b;
    padding: 1.07143em 0.71429em !important;
    font-size: 1.14286em;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    border: 0 solid;
    line-height: 1;
    font-weight: 600;
    position: relative;
    background: inherit !important;
    margin: 0 !important;
}
.ReactTable .pagination-bottom .Table__pageButton.Table__pageButton--active {
    color: #001f96;
}
.ReactTable .pagination-bottom .Table__pageButton.Table__pageButton--active:after {
    background-color: #001f96;
}
.ReactTable .pagination-bottom .Table__pageButton:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    width: 1.42857em;
    height: 0.21429em;
    background-color: #fff;
}
.ReactTable .pagination-bottom .Table__pageButton:focus, .ReactTable .pagination-bottom .Table__pageButton:hover {
    background-color: #fff;
    color: #001f96 !important;
    border: 0;
}
.ReactTable .pagination-bottom .normal-select {
    line-height: normal;
    border-radius: 0;
    font-size: 1em;
    padding: 0.35714em 2.5em 0.35714em 0.92857em;
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0.07143em solid #e5e5e5;
    color: #434343;
    font-weight: 400;
    /* background: #fff url(public/images/select-arrow.png) no-repeat 100%; */
}
.ReactTable .rt-noData {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    z-index: 1;
    pointer-events: none;
    padding: 20px;
    color: rgba(0, 0, 0, 0.5);
}
.ReactTable .-loading {
    display: none;
}


.ReactTable .cell-text {
    padding: 10px;
}

.ReactTable .staff-actions {
    z-index: 4;
    background-color: white;
    position: absolute;
}

.m-0 {
    margin: 0 !important;
}

.ReactTable .rt-table .rt-tr .rt-th{
    padding: 10px;
}

.admin-bookings .ReactTable .rt-table .rt-tr .rt-td, 
.booking-cycle-page .ReactTable .rt-table .rt-tr .rt-td, 
.booking-offlist-page .ReactTable .rt-table .rt-tr .rt-td, 
.cancelled-bookings .ReactTable .rt-table .rt-tr .rt-td,
.doctors-table .ReactTable .rt-table .rt-tr .rt-td,
.hospitals-table .ReactTable .rt-table .rt-tr .rt-td,
.users-table .ReactTable .rt-table .rt-tr .rt-td,
.probes-table .ReactTable .rt-table .rt-tr .rt-td,
.procedures-table .ReactTable .rt-table .rt-tr .rt-td,
.invoices-table .ReactTable .rt-table .rt-tr .rt-td,
.patients-booking-table .ReactTable .rt-table .rt-tr .rt-td,
.secretary-cancelled-bookings-table .ReactTable .rt-table .rt-tr .rt-td
{
    padding: 10px;
}


.sidebar {
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    /* width:15%; */
}

.nav { 
    display: flex;
    text-decoration: none;
    text-justify: auto;
    text-align: center;
}

.logo {
    height: 100px;
    width: 100px; 
    /* margin-bottom: 20px; */
}